import Axios, { InternalAxiosRequestConfig } from "axios";
import firebase, { ensureAuthenticated } from "@/plugins/firebase";

const axios = Axios.create({
    withCredentials: false,
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        "X-Client-Version": process.env.VUE_APP_COMMIT_HASH,
    },
});

export function setAuthHeader(token: string | null) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

axios.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    await ensureAuthenticated();
    if (firebase.auth().currentUser) {
        const token = await firebase.auth().currentUser?.getIdToken();
        if (config.headers) {
            config.headers["Authorization"] = "Bearer " + token;
        }
    }
    return config;
});

export type ApiFilterOptions = {
    sortBy: string;
    sortDesc: boolean;
    page: number;
    itemsPerPage: number;
    query: string | null;
};

export interface ApiResponse {
    error: string;
    message: string;
    data: object | null;
}

export default axios;
