import currency from "currency.js";

export default function (value: string | number | null): string {
    if (value === null || value === "") return "-";
    const result = currency(value).format({
        fromCents: true,
        precision: 0,
        pattern: `! #`,
        symbol: "₦",
    });

    return result.replace(".00", "");
}
