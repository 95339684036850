import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify, { isDarkModeOn } from "./plugins/vuetify";
import VTooltip from "v-tooltip";
import "@/sass/app.scss";
import { Constants } from "./plugins/constants";
import { VueReCaptcha } from "vue-recaptcha-v3";
import currency from "@/filters/currency";
import capitalize from "@/filters/capitalize";
import "@/plugins/sentry";
import "@/plugins/firebase";
import time from "@/filters/time";
import titleCase from "@/filters/titleCase";
import dateTime from "@/filters/dateTime";
import timeago from "@/filters/timeago";
import date from "@/filters/date";
import longDateTime from "@/filters/longDate";
import "flag-icon-css/css/flag-icon.min.css";
import htmlDisplayName from "@/filters/htmlDisplayName";
import naira from "@/filters/naira";
import { isInDevelopment } from "@/plugins/utils";
import VueMeta from "vue-meta";
import VueConfetti from "vue-confetti";
import dateHtml from "@/filters/dateHtml";

Vue.use(VueMeta);
Vue.use(VTooltip);
Vue.use(Constants);
Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
    loaderOptions: {
        autoHideBadge: true,
        renderParameters: {
            theme: isDarkModeOn() ? "dark" : "light",
        },
    },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
Vue.use(VueConfetti as any);

Vue.config.productionTip = isInDevelopment();

Vue.filter("capitalize", capitalize);
Vue.filter("currency", currency);
Vue.filter("time", time);
Vue.filter("titleCase", titleCase);
Vue.filter("dateTime", dateTime);
Vue.filter("timeago", timeago);
Vue.filter("date", date);
Vue.filter("dateHtml", dateHtml);
Vue.filter("naira", naira);
Vue.filter("longDateTime", longDateTime);
Vue.filter("htmlDisplayName", htmlDisplayName);

if (isDarkModeOn()) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    document.getElementsByTagName("meta")["theme-color"].content = "#121212";
}

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
