export default function (value: string | null | Date) {
    const date = new Date(value || "now");
    const formatter = new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        year: "numeric",
        month: "short",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    });
    return formatter.format(date);
}
