export const isAndroid = () => {
    return /(android)/i.test(navigator.userAgent);
};

// Detects if the app is standalone
export const isIosStandaloneMode = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return !!(window.navigator as any)?.standalone;
};

export const isChromeStandaloneMode = () => {
    return window.matchMedia("(display-mode: standalone)").matches;
};

export const isInStandaloneMode = () => {
    return isIosStandaloneMode() || isChromeStandaloneMode();
};

// Detects if device is on iOS
export const isIos = () => {
    return /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
};

export const isChrome = () => {
    return (
        /Google Inc/.test(window.navigator.vendor) ||
        (/CriOS/i.test(window.navigator.userAgent) && isIos())
    );
};

export const userAgent = () => {
    return window.navigator.userAgent;
};

export const isSafari = () => {
    return (
        /^((?!chrome|android).)*safari/i.test(
            window.navigator.userAgent.toLowerCase()
        ) && !isChrome()
    );
};

export const isWebView = (): boolean => {
    return (
        isSafari() &&
        isInStandaloneMode() &&
        window.navigator.userAgent.indexOf("Version/15.0") !== -1
    );
};

export const isFirefox = () => {
    return /Firefox/.test(window.navigator.userAgent);
};

export const appVersion = () => {
    return process.env.VUE_APP_COMMIT_HASH;
};

export const isInDevelopment = (): boolean => {
    return process.env.NODE_ENV === "development";
};
