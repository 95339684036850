import currency from "currency.js";

function formatXaf(value: string) {
    if (value === null || value === "") return "-";
    const result = currency(value).format({
        fromCents: true,
        precision: 0,
        pattern: `# !`,
        symbol: "FCFA",
    });
    return result.replace(".00 FCFA", " FCFA");
}

export default function (
    value: string | number | null,
    code: string = "XAF"
): string {
    if (value === null || value === "") return "-";
    if (code === "XAF") {
        return formatXaf(value as string);
    }
    return new Intl.NumberFormat(undefined, {
        style: "currency",
        currency: code,
        minimumFractionDigits: 2,
    })
        .format(parseFloat(value as string) ?? 0)
        .replace("US$", "$");
}
