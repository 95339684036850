import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isVisible)?_c(VDialog,{attrs:{"persistent":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.notificationButtonClicked}},[_c(VIcon,_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.notificationIcon)+" ")])],1)]}}],null,false,2212769579),model:{value:(_vm.notificationDialogOpen),callback:function ($$v) {_vm.notificationDialogOpen=$$v},expression:"notificationDialogOpen"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" Enable Notifications ")]),(_vm.isRegistering)?_c(VCardText,[_c('loading-spinner')],1):_vm._e(),(!_vm.isRegistering)?_c(VCardText,[_vm._v(" Would you like us to send you a notification when your subscription is about to expire? ")]):_vm._e(),(!_vm.isRegistering)?_c(VCardActions,[_c(VBtn,{attrs:{"color":"green"},on:{"click":_vm.registerUser}},[_vm._v(" Yes ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" No ")])],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }