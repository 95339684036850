export interface SerializedStatus {
    status: string;
    timestamp: string;
    failures?: Record<string, string>;
}

export default class Status {
    private readonly _status: string;
    private readonly _timestamp: Date;
    private readonly _failures?: Record<string, string>;
    private readonly _raw: SerializedStatus;

    constructor(status: SerializedStatus) {
        this._status = status.status;
        this._timestamp = new Date(status.timestamp);
        this._failures = status.failures;
        this._raw = status;
    }

    get status(): string {
        return this._status;
    }

    get timestamp(): Date {
        return this._timestamp;
    }

    get failures(): Record<string, string> | undefined {
        return this._failures;
    }

    get raw(): SerializedStatus {
        return this._raw;
    }
}
