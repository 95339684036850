




import { Vue, Component } from "vue-property-decorator";

@Component
export default class Logo extends Vue {
    src: string = require("@/assets/img/Logo-Dark.svg");

    get height(): number | string {
        switch (this.$vuetify.breakpoint.name) {
            case "xs":
                return 40;
            case "sm":
                return 60;
            default:
                return 70;
        }
    }
}
