import { AxiosResponse } from "axios";
import Axios from "./axios";

class Version {
    private axios: typeof Axios;

    constructor(axios: typeof Axios) {
        this.axios = axios;
    }

    get(): Promise<AxiosResponse> {
        return this.axios.get(`/version`);
    }
}

export default new Version(Axios);
