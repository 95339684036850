export default function (value: string | null | Date) {
    const nth = (n: number) => {
        return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
    };
    const date = new Date(value || "now");
    return `${date.toLocaleString(undefined, { day: "numeric" })}<sup>${nth(
        date.getDate()
    )}</sup> of ${date.toLocaleString(undefined, {
        month: "long",
    })} ${date.toLocaleString(undefined, { year: "numeric" })}`;
}
