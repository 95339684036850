interface IdentifyParams {
    email: string;
    name: string;
}

interface Rudderstack {
    identify(id: string, params: IdentifyParams): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    track(eventId: string, params: any): void;
}

export const getRudderstack = (): Rudderstack => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (window as any).rudderanalytics as Rudderstack;
};
