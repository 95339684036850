import { ROUTE_NAMES } from "@/router";
import _Vue from "vue";
import { customSearch } from "@/types/veutify";
import { DASHBOARD_CHART_IDS } from "@/models/dashboard-chart";

export const CONSTANTS = {
    ROUTE_NAMES,
    NOTIFICATION_EVENTS: {
        ERROR: "notification_events_error",
        SUCCESS: "notification_events_success",
        INFO: "notification_events_info",
    },
    DRAWER_EVENTS: {
        HIDE: "drawer_events.hide",
    },
    EVENTS: {
        ORDER_PLACED: "events_order_placed",
    },
    VUETIFY: {
        CUSTOM_SEARCH: customSearch,
    },
    APP: {
        NAME: process.env.VUE_APP_APP_NAME,
        SUPPORT_EMAIL: process.env.VUE_APP_APP_SUPPORT_EMAIL,
    },
    DASHBOARD_CHART_IDS,
};

export function Constants(Vue: typeof _Vue): void {
    Vue.prototype.$constants = CONSTANTS;
}
