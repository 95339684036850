import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.updateButtonClicked}},[_c(VBadge,{attrs:{"color":"error","dot":""}},[_c(VIcon,_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.updateIcon)+" ")])],1)],1)]}}]),model:{value:(_vm.notificationDialogOpen),callback:function ($$v) {_vm.notificationDialogOpen=$$v},expression:"notificationDialogOpen"}},[_c(VCard,[(!_vm.isRegistering)?_c(VCardTitle,{staticClass:"headline"},[_vm._v(" A new version is available ")]):_vm._e(),(_vm.isRegistering)?_c(VCardText,[_c('loading-spinner',{staticClass:"pt-6"}),_c('p',{staticClass:"overline text-center"},[_vm._v("Updating")])],1):_vm._e(),(!_vm.isRegistering)?_c(VCardText,[_vm._v(" Would you like to update the "+_vm._s(_vm.appName)+" "+_vm._s(_vm.platformName)+" to the latest version? ")]):_vm._e(),(!_vm.isRegistering)?_c(VCardActions,[_c(VBtn,{attrs:{"color":"green"},on:{"click":_vm.forceUpdate}},[_vm._v(" Yes ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" No ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }