
































import { Vue, Component } from "vue-property-decorator";
import { mdiBellOutline } from "@mdi/js";
import firebase, { addAnalyticsEvent } from "@/plugins/firebase";
import { captureSentryException } from "@/plugins/sentry";
import { User } from "@/models/user";
import UserApi from "@/services/api/user";
import { Getter } from "vuex-class";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
@Component({
    components: { LoadingSpinner },
})
export default class PushNotificationsButton extends Vue {
    @Getter("user") user!: User;
    notificationDialogOpen = false;
    notificationIcon: string = mdiBellOutline;
    isRegistering: boolean = false;

    get isVisible() {
        return this.user;
    }

    mounted() {
        // set timeout so auth should be done.
        setTimeout(this.updateToken, 10000);
    }

    notificationButtonClicked(event: Event) {
        event.preventDefault();
        addAnalyticsEvent("notification_button_clicked");
    }

    closeDialog(event: Event) {
        event.preventDefault();
        this.notificationDialogOpen = false;
    }

    handleError(error: Error) {
        this.isRegistering = false;
        captureSentryException(error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.message
        );
    }

    async updateToken() {
        if (!firebase.messaging.isSupported()) {
            return;
        }

        const permission = await navigator.permissions.query({
            name: "notifications",
        });

        if (permission.state !== "granted") {
            return false;
        }

        firebase
            .messaging()
            .getToken({ vapidKey: process.env.FIREBASE_MESSAGING_TOKEN })
            .then((currentToken) => {
                if (!currentToken) {
                    addAnalyticsEvent("update_notification_prompt_rejected");
                    return;
                }

                if (!this.user) {
                    return;
                }

                this.$recaptcha("update_fcm_token")
                    .then((captcha: string) => {
                        UserApi.storeToken(
                            this.user.uid,
                            captcha,
                            currentToken
                        ).catch(this.handleError);
                    })
                    .catch(this.handleError);
            })
            .catch(this.handleError);
    }

    registerUser(event: Event) {
        event.preventDefault();
        if (!firebase.messaging.isSupported()) {
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.INFO,
                "Sorry push notifications are not yet available on your browser."
            );
            return;
        }

        firebase
            .messaging()
            .getToken({ vapidKey: process.env.FIREBASE_MESSAGING_TOKEN })
            .then((currentToken) => {
                if (!currentToken) {
                    addAnalyticsEvent("notification_prompt_rejected");
                    return;
                }

                this.isRegistering = true;
                this.$recaptcha("store_fcm_token")
                    .then((captcha: string) => {
                        UserApi.storeToken(this.user.uid, captcha, currentToken)
                            .then(() => {
                                this.notificationDialogOpen = false;
                                this.isRegistering = false;
                                this.$root.$emit(
                                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                                    "We have successfully registered your device for notifications"
                                );
                            })
                            .catch(this.handleError);
                    })
                    .catch(this.handleError);
            })
            .catch(this.handleError);
    }
}
