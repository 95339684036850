import { AxiosResponse } from "axios";
import Axios from "./axios";

export interface UpdateProfileInput {
    name: string | null;
    email: string | null;
    dstvPackageId: string | null;
    smartcardNumber: string | null;
    paymentMethod: string | null;
    paymentPhoneNumber: string | null;
    captcha: string;
    nameConfirmation: boolean;
}

export interface UpdateProfileSettingsRequest {
    name: string | null;
    email: string | null;
    address: string | null;
    // eslint-disable-next-line @typescript-eslint/camelcase
    payment_method: string | null;
    // eslint-disable-next-line @typescript-eslint/camelcase
    payment_phone_number: string | null;
    captcha: string;
}

export interface UpdateDstvSettingsRequest {
    // eslint-disable-next-line @typescript-eslint/camelcase
    dstv_package_id: string | null;
    // eslint-disable-next-line @typescript-eslint/camelcase
    smartcard_number: string | null;
    // eslint-disable-next-line @typescript-eslint/camelcase
    name_confirmation: boolean;
    captcha: string;
}

export interface UpdateCanalplusSettingsRequest {
    // eslint-disable-next-line @typescript-eslint/camelcase
    canalplus_package: string | null;
    // eslint-disable-next-line @typescript-eslint/camelcase
    card_number: string | null;
    captcha: string;
}

export interface UpdateAirtimeSettingsRequest {
    // eslint-disable-next-line @typescript-eslint/camelcase
    recipient_phone_number: string;
    // eslint-disable-next-line @typescript-eslint/camelcase
    phone_network: string;
    amount: number;
    captcha: string;
}

export interface UpdateExchangeSettingsRequest {
    // eslint-disable-next-line @typescript-eslint/camelcase
    recipient_phone_number: string;
    // eslint-disable-next-line @typescript-eslint/camelcase
    phone_network: string;
    amount: number;
    captcha: string;
}

export interface UpdateEneoSettingsRequest {
    amount: number | null;
    captcha: string;
    meter_type: string;
    meter_number: string | null;
}

export interface UpdateCamwaterSettingsRequest {
    contract_number: string;
    captcha: string;
}

export interface UpdateNotificationSettingsRequest {
    // eslint-disable-next-line @typescript-eslint/camelcase
    newsletter_email: boolean;
    // eslint-disable-next-line @typescript-eslint/camelcase
    dstv_reminder: boolean;
    captcha: string;
}

class User {
    private axios: typeof Axios;

    constructor(axios: typeof Axios) {
        this.axios = axios;
    }

    getProfile(userId: string): Promise<AxiosResponse> {
        return this.axios.get(`/users/${userId}/profile`);
    }

    adminIndex(): Promise<AxiosResponse> {
        return this.axios.get(`/admin/users`);
    }

    storeToken(
        userId: string,
        captcha: string,
        token: string
    ): Promise<AxiosResponse> {
        return this.axios.post(`/users/${userId}/fcm-tokens`, {
            captcha,
            token,
        });
    }

    updateProfileSettings(
        userId: string,
        request: UpdateProfileSettingsRequest
    ): Promise<AxiosResponse> {
        return this.axios.put(`/users/${userId}/profile-settings`, request);
    }

    updateAirtimeSettings(
        userId: string,
        request: UpdateAirtimeSettingsRequest
    ): Promise<AxiosResponse> {
        return this.axios.put(`/users/${userId}/airtime-settings`, request);
    }

    updateExchangeSettings(
        userId: string,
        request: UpdateExchangeSettingsRequest
    ): Promise<AxiosResponse> {
        return this.axios.put(`/users/${userId}/exchange-settings`, request);
    }

    updateNotificationSettings(
        userId: string,
        request: UpdateNotificationSettingsRequest
    ): Promise<AxiosResponse> {
        return this.axios.put(
            `/users/${userId}/notification-settings`,
            request
        );
    }

    updateDstvSettings(
        userId: string,
        request: UpdateDstvSettingsRequest
    ): Promise<AxiosResponse> {
        return this.axios.put(`/users/${userId}/dstv-settings`, request);
    }

    updateCanalplusSettings(
        userId: string,
        request: UpdateCanalplusSettingsRequest
    ): Promise<AxiosResponse> {
        return this.axios.put(`/users/${userId}/canalplus-settings`, request);
    }

    updateEneoSettings(
        userId: string,
        request: UpdateEneoSettingsRequest
    ): Promise<AxiosResponse> {
        return this.axios.put(`/users/${userId}/eneo-settings`, request);
    }

    updateCamwaterSettings(
        userId: string,
        request: UpdateCamwaterSettingsRequest
    ): Promise<AxiosResponse> {
        return this.axios.put(`/users/${userId}/camwater-settings`, request);
    }
}

export default new User(Axios);
