export default function (value: string | null | Date) {
    return new Date(value || "now").toLocaleString(undefined, {
        weekday: "long",
        year: "numeric",
        month: "long",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        day: "numeric",
    });
}
