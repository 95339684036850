import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export const isDarkModeOn = () => {
    return (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
    );
};

export default new Vuetify({
    theme: {
        dark: isDarkModeOn(),
        themes: {
            light: {
                primary: "#1A237E", //indigo darken-4
            },
            dark: {
                primary: "#1565C0", // blue darken-3
            },
        },
    },
    icons: {
        iconfont: "mdiSvg",
    },
});
