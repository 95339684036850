import { AxiosResponse } from "axios";
import Axios from "./axios";

class Status {
    private axios: typeof Axios;

    constructor(axios: typeof Axios) {
        this.axios = axios;
    }

    index(): Promise<AxiosResponse> {
        return this.axios.get("/status/");
    }
}

export default new Status(Axios);
