import { AxiosResponse } from "axios";
import Axios from "./axios";

class Referral {
    private axios: typeof Axios;

    constructor(axios: typeof Axios) {
        this.axios = axios;
    }

    show(userId: string): Promise<AxiosResponse> {
        return this.axios.get(`/referrals/${userId}`);
    }

    getSettings(): Promise<AxiosResponse> {
        return this.axios.get(`/referrals/settings`);
    }

    storeClick(referralCode: string): Promise<AxiosResponse> {
        return this.axios.post(`/referrals/clicks`, {
            // eslint-disable-next-line @typescript-eslint/camelcase
            referral_code: referralCode,
        });
    }

    update(
        userId: string,
        phoneNumber: string,
        captcha: string
    ): Promise<AxiosResponse> {
        return this.axios.put(`/referrals/${userId}`, {
            // eslint-disable-next-line @typescript-eslint/camelcase
            phone_number: phoneNumber,
            captcha,
        });
    }
}

export default new Referral(Axios);
