/* eslint-disable no-console */

import { register } from "register-service-worker";
import { addAnalyticsEvent } from "@/plugins/firebase";

if (process.env.NODE_ENV === "production") {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            addAnalyticsEvent("app_is_being_served_from_cache");
            console.log(
                "App is being served from cache by a service worker.\n" +
                    "For more details, visit https://goo.gl/AFskqB"
            );
        },
        registered() {
            addAnalyticsEvent("service_ready");
            console.log("Service worker has been registered.");
        },
        cached() {
            addAnalyticsEvent("content_cached_for_offline_use");
            console.log("Content has been cached for offline use.");
        },
        updatefound() {
            addAnalyticsEvent("new_content_is_downloading");
            console.log("update found");
        },
        updated() {
            addAnalyticsEvent("new_content_available");
            console.log("Contend updated. Please refresh");
        },
        offline() {
            addAnalyticsEvent("user_is_offline");
            console.log(
                "No internet connection found. App is running in offline mode."
            );
        },
        error(error) {
            addAnalyticsEvent("service_worker_registration_error");
            console.error("Error during service worker registration:", error);
        },
    });
}
