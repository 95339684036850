import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";
import {
    appVersion,
    isAndroid,
    isInStandaloneMode,
    isIos,
    userAgent,
} from "@/plugins/utils";
import { isDarkModeOn } from "@/plugins/vuetify";
import { fetchFromLocalStorage } from "@/models/user";
import { captureSentryException } from "@/plugins/sentry";
import { getRudderstack } from "@/plugins/rudderstack";

const firebaseConfig = {
    apiKey: "AIzaSyDU13GBOtQTNTWeWFu4m4LnhnWceyNo62I",
    authDomain: "nyanga-pay.firebaseapp.com",
    databaseURL: "https://nyanga-pay.firebaseio.com",
    projectId: "nyanga-pay",
    storageBucket: "nyanga-pay.appspot.com",
    messagingSenderId: "868464663524",
    appId: "1:868464663524:web:6197c5a122b15a548505f1",
    measurementId: "G-1YLJD8EKWP",
};

let analyticsIsSupported = false;

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics.isSupported().then((isSupported: boolean) => {
    if (isSupported) {
        analyticsIsSupported = isSupported;
        firebase.analytics();
    }
});

export const addAnalyticsEvent = (
    eventName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventParams?: { [key: string]: any }
) => {
    const user = fetchFromLocalStorage();
    const params = {
        userAgent: userAgent(),
        appVersion: appVersion(),
        languages: navigator.languages,
        page: window.location.pathname ?? "/",
        isDarkMode: isDarkModeOn(),
        isStandalone: isInStandaloneMode(),
        environment: process.env.NODE_ENV,
        isIos: isIos(),
        userId: user?.uid,
        isAndroid: isAndroid(),
        ...eventParams,
    };

    if (analyticsIsSupported) {
        getRudderstack().track(eventName, params);
        firebase.analytics().logEvent(eventName, params);
    }

    if (eventName.startsWith("error_")) {
        captureSentryException({
            eventName,
            ...params,
        });
    }
};

export const ensureAuthenticated = () => {
    return new Promise((resolve) => {
        resolve(true);
    });
};

export default firebase;
