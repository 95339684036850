import capitalize from "@/filters/capitalize";

export default function (value: string | null) {
    if (!value) {
        return "";
    }

    return value
        .split(" ")
        .map((word) => capitalize(word))
        .join(" ");
}
