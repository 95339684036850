function sanitize(value: string): string {
    const map = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#x27;",
        "/": "&#x2F;",
    } as Record<string, string>;
    const reg = /[&<>"'/]/gi;
    return value.replace(reg, (match: string) => map[match]);
}

export default function (displayName: string | null) {
    if (!displayName) {
        return null;
    }
    return sanitize(displayName.substr(0, 22))
        .split("+")
        .join(" ")
        .split("🇨🇲")
        .join('<span class="flag-icon flag-icon-cm"></span>')
        .split("🇳🇬")
        .join('<span class="flag-icon flag-icon-ng"></span>');
}
